// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page icons
import foodTruckIcon from "assets/img/icons/food-truck.svg"
import foodVendorsAboutStyle from "assets/jss/material-kit-pro-react/views/foodVendorsPageSections/foodVendorsAboutStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(foodVendorsAboutStyle)

export default function FoodVendorsAbout() {
  const { foodVendorsAboutImage1 } = useStaticQuery(graphql`
    query foodVendorsAboutImages {
      foodVendorsAboutImage1: file(
        relativePath: { eq: "food-vendors/food-vendor-2.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>
              Chilimania is much more than just chili!
            </h2>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={7} className={classes.mlAuto}>
            <div className={classes.kleanTunnelIcon}>
              <img
                src={foodTruckIcon}
                className={classes.foodTruckIcon}
                alt="Food Truck Icon"
              />
            </div>
            <p className={classes.description}>
              We might have “chili” in our name, but that doesn’t mean we don’t
              offer a wide variety of other foods and desserts to indulge in
              throughout the day! Whether you want a special sweet treat or are
              the impatient type looking to score some chili before the public
              tasting at 3 PM, we’ve got you covered.
              <br />
              <br />
              The best part is that many of our food vendor stands are run by
              local Edgerton nonprofit groups, helping them raise money for
              their respective causes. It’s a win-win for all involved!
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
            <GatsbyImage
              image={foodVendorsAboutImage1.childImageSharp.gatsbyImageData}
              className={classes.foodVendorsAboutImage}
              alt="Bloody Mary Picture"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
